import $ from "jquery";
export default class Home {
  constructor() {
    this.fvAnimation();
  }

  fvAnimation() {
    const target = document.body; // body要素を監視
    const observer = new MutationObserver((records) => {
      //toggleLoadingClass()が発動したことを確認
      if (records[0].target.className === "is-loaded") {
        fvVisible();
        observer.disconnect();
      }
    });

    observer.observe(target, {
      attributes: true,
      attributeFilter: ["class"],
    });

    function fvVisible() {
      setTimeout(() => {
        $(".jsc-top-fv-content").removeClass("is-hidden");
        $(".jsc-top-fv-logo").fadeIn();
      }, 1000);
    }
  }
}
