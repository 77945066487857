import $ from "jquery";

export default class Loading {
  constructor() {
    this.toggleLoadingClass();
  }

  toggleLoadingClass() {
    //読み込み終わりでfadeout
    $(window).on("load", () => {
      $("body").removeClass(
        "is-loading",
        setTimeout(() => {
          $("body").addClass("is-loaded");
        }, 800)
      );
      $(".loading-insert").removeClass("is-month");
    });
    //ページ遷移時fadein
    $('a:not([href^="/#"]):not([target])').on("click", (e) => {
      if (!$(e.currentTarget).attr("href").match("/schedule/2")) {
        e.preventDefault();
        const eventUrl = $(e.currentTarget).attr("href");
        $("body").addClass("is-loading");
        setTimeout(() => {
          console.log(eventUrl);
          window.location.href = eventUrl;
        }, 800);
      }
    });
  }
}
