import $ from "jquery";

export default class Btn {
  constructor() {
    this.toggleArchiveBtn();
  }

  toggleArchiveBtn() {
    const $archiveBtn = $(".jsc-toggle-archive-btn");
    const $archiveList = $(".jsc-toggle-archive-list");
    $archiveBtn.on("click", () => {
      $archiveList.slideToggle();
    });
  }
}
