import Loading from "./Loading";
import Btn from "./Btn";
import Home from "../home";
export default class Router {
  constructor() {
    this.model = {
      pathName: location.pathname,
    };

    this.initRouting();
  }

  initRouting() {
    const pathName = this.model.pathName;
    switch (pathName) {
      case "/":
        new Loading();
        new Home();
        break;
      default:
        new Loading();
        new Btn();
        break;
    }
  }
}
